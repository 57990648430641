import React from 'react';
import './AboutUs.css'; // Make sure to create and import a CSS file for styling

const AboutUs = () => {
  return (
    <div className="about-container">
      <div className="company-name">
        GOZO PRODUCTS PRIVATE LIMITED
      </div>
      <div className="company-intro">
        <h3>Company CEO: Bharat Balakrishna Dave</h3>
        <p>
          Incorporated on 24th March 2021, in Mumbai, Gozo Products Pvt Ltd is a prominent wholesaler specializing in plywood, solid surface sheets, window blinds, and wall panels. With a broad distribution network, the company ensures its products are accessible to clients, reflecting its strong client-centric approach.
        </p>
        <p>
          Under the leadership of CEO Bharat Balakrishna Dave, whose extensive industry experience and astute business insight have been instrumental in Gozo Products Pvt Ltd's significant growth and reputation for quality products.
        </p>
      </div>
      <div className="company-products">
        <h3>Our Products</h3>
        <p>
          Gozo Products Pvt Ltd, established in 2021 in Mumbai, India, is a prominent wholesaler specializing in plywood, solid surface sheets, window blinds, and wall panels. With a broad distribution network, the company ensures its products are accessible to clients, reflecting its strong client-centric approach. This focus on customer needs has facilitated the expansion of its market throughout the country.
        </p>
      </div>
      <table className="company-details">
        <tbody>
          <tr>
            <th>Registration Number</th>
            <td>357759</td>
          </tr>
          <tr>
            <th>GST No</th>
            <td>27AAICG9380J1Z0</td>
          </tr>
          <tr>
            <th>Class of Company</th>
            <td>Private</td>
          </tr>
          <tr>
            <th>Date of Incorporation</th>
            <td>24 March 2021</td>
          </tr>
          <tr>
            <th>Activity</th>
            <td>Manufacture of products of wood, cork, straw, and plaiting materials</td>
          </tr>
          <tr>
            <th>Address</th>
            <td>SHOP NO 16, GROUND FLOOR, 1/2, NEW MUNICIPAL BUILDING JAVJI DADAJI MARG, NANA CHOWK, GRANT ROAD, Mumbai, Maharashtra, India - 400007</td>
          </tr>
          <tr>
            <th>Primary Competitive Advantage</th>
            <td>
              <ul>
                <li>Experienced R &amp; D Department</li>
                <li>Provide Customized Solutions</li>
                <li>Good Financial Position &amp; TQM</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AboutUs;
