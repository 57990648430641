import React, { useState } from 'react';
import './ContactUs.css';

const ContactUs = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [requirements, setRequirements] = useState('');
  const [error, setError] = useState('');

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleRequirementsChange = (e) => {
    setRequirements(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!phoneNumber || !name || !requirements) {
      setError('All fields are required');
      return;
    }

    const message = `Name: ${name}\nPhone Number: ${phoneNumber}\nRequirements: ${requirements}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=9820533571&text=${encodeURIComponent(message)}`;
    
    window.open(whatsappUrl, '_blank');

    setPhoneNumber('');
    setName('');
    setRequirements('');
    setError('');
  };

  return (
    <div className="contact-container">
      <div className="contact-details">
        <h2>Contact Details</h2>
        <p><strong>Contact Person:</strong> Mr. Bharat Dave</p>
        <p><strong>Address:</strong></p>
        <p>Shop No 16, Ground Floor, 1/2, <br />
         New Municipal Building Javji Dadaji Marg, Nana Chowk, <br/>
        Grant Road ,Mumbai, Maharashtra, India - 400007</p>
        <p><strong>Call Us:</strong> 98212 20077</p>
      </div>
      <div className="contact-form">
        <h2>Contact Us</h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit} className="form">
          <div className="form-group">
            <input
              type="text"
              placeholder="Describe your requirements in detail"
              value={requirements}
              onChange={handleRequirementsChange}
              className="form-input"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              className="form-input"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              placeholder="Enter your name"
              value={name}
              onChange={handleNameChange}
              className="form-input"
              required
            />
          </div>
          <button type="submit" className="contact-now-btn">Contact Now</button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
