import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import display1 from '../Assets/Products/home page/image1.jpeg'; 
// import display3 from '../Assets/Products/home page/image2.jpg';
import display4 from '../Assets/Products/home page/image3.png';
import display5 from '../Assets/Products/home page/image4.jpg';
import louversPanel from '../Assets/Products/louvers/image (1).jpg';
import acrylicSheet from '../Assets/Products/acrylic/image1.jpg';
import charcoalSheet from '../Assets/Products/stone veneer/image (1).jpg';
// import laminateSheet from '../Assets/Products/laminate/image (1).jpg';
// import veneerSheet from '../Assets/Products/veneer/image (1).jpg';
// import plywood from '../Assets/Products/plywood/image (1).jpg';
// import pvc from '../Assets/Products/pvc/image (1).jpg';
import { Link } from 'react-router-dom';

import './Home.css'; // Import the CSS file

const Home = () => {
  return (
    <div>
      <div className="carousel-container">
        <Carousel>
          {/* <Carousel.Item>
            <img
              className="d-block w-100"
              src={display3}
              alt="PVC Core Laminate Sheet"
            />
          </Carousel.Item> */}
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={display1}
              alt="Decorative Charcoal Sheet"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={display4}
              alt="Sainik Laminated Sheet"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={display5}
              alt="Slat Louvers Panels"
            />
          </Carousel.Item>
        </Carousel>
        <div className="carousel-caption">
          <h3>Explore Our Premium Sheets Collection</h3>
        </div>
      </div>

      <div className="intro-section">
        <h2>Welcome to Our Premium Plywood Destination</h2>
        <p>
          Your premier destination for high-quality wholesale plywood. Catering to contractors and builders, we provide a wide range of plywood products to suit all your requirements. Our extensive distribution network ensures that our products are easily accessible to clients nationwide. Committed to excellence and offering competitive pricing, we guarantee the best value for your projects.
        </p>
      </div>

      <div className="products-section">
        <h2>Our Products</h2>
        <p>We specialize in manufacturing high-quality products for your interiors and exteriors. Our offerings are crafted to enhance the beauty and functionality of your living spaces.</p>
        <div className="products-grid">
          <div className="product-item">
            <img src={louversPanel} alt="Louvers panel" />
            <h3>Louvers panel</h3>
          </div>
          <div className="product-item">
            <img src={acrylicSheet} alt="Acrylic Sheet" />
            <h3>Acrylic Sheet</h3>
          </div>
          <div className="product-item">
            <img src={charcoalSheet} alt="Charcoal Sheet" />
            <h3>Stone Veneer</h3>
          </div>
          {/* <div className="product-item"> */}
            {/* <img src={laminateSheet} alt="Laminate Sheet" />
            <h3>Laminate Sheet</h3>
          </div>
          <div className="product-item">
            <img src={veneerSheet} alt="Veneer Sheet" />
            <h3>Veneer Sheet</h3>
          </div>
          <div className="product-item">
            <img src={plywood} alt="Plywood" />
            <h3>Plywood</h3>
          </div>
          <div className="product-item">
            <img src={pvc} alt="Decorative Acrylic Sheets" />
            <h3>PVC Sheets</h3>
          </div> */}
          <div className="product-item view-all">
            <h3><Link to="/products">View All Products</Link></h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
