import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Home from './Pages/Home';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import Products from './Pages/Products';
import AboutUs from './Pages/AboutUs'
// import ErrorBoundary from './Components/ErrorBoundary'; // Import ErrorBoundary
import Contact from './Pages/ContactUs';
function App() {
  return (
      <Router>
        <Navbar />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contacts" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
  );
}

export default App;
