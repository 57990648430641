import React from 'react';
import './Footer.css'; 
import logo from '../Assets/Products/logo.png';
import { Link } from 'react-router-dom';
// import logo from './logo.png';
const Footer = () => {
    return (
        <>
        <div className="home-container">
      <div className="section">
		<Link to="/about-us">
        <div className="section-content">
          <p className="no-underline">GET TO KNOW US!</p>
          <p className="no-underline">ABOUT US</p>
        </div>
		</Link>

      </div>
	  
      <div className="section">
	  <Link to="/contacts">

        <div className="section-content1">
          <p className="no-underline">REQUEST INFORMATION</p>
          <p className="no-underline">CONTACT US</p>
        </div>
		</Link>
      </div>
      
    </div>
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-left">
                    <div className="footer-logo">
                        <img src={logo} alt="Gozo Logo" />
                    </div>
                    <address>
                        <p>Shop No 16, Ground Floor, 1/2, New Municipal Building Javji Dadaji Marg, Nana Chowk, Grant Road ,Mumbai, Maharashtra, India - 400007</p>
                        {/* <p>📞 (+91) 79772 20618</p> */}
                        <p>WhatsApp: (+91)98212 200771</p>
                        <p>📧 <a href="mailto:gozoproductspvtltd@gmail.com">gozoproductspvtltd@gmail.com</a></p>
                                            </address>
                </div>
                <div className="footer-center">
                    <h3>OPENING HOURS</h3>
                    <p><strong>Monday - Saturday</strong></p>
                    <p>10.30 am / 8.00pm</p>
                    <p><strong>Closed: Sunday </strong> </p>
                </div>
              
            </div>
        </footer>
        </>
    );
};

export default Footer;
