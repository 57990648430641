import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';
import './Products.css';
import LouverPanel1 from '../Assets/Products/louvers/image (1).jpg';
import LouverPanel2 from '../Assets/Products/louvers/image (2).jpg';
import LouverPanel3 from '../Assets/Products/louvers/image (3).jpg';
import acrylicSheet1 from '../Assets/Products/acrylic/image1.jpg';
import acrylicSheet2 from '../Assets/Products/acrylic/image2.jpg';
import acrylicSheet3 from '../Assets/Products/acrylic/image3.jpg';
import stoneveneer1 from '../Assets/Products/stone veneer/image (1).jpg';
import stoneveneer2 from '../Assets/Products/stone veneer/image (2).jpg';
import stoneveneer3 from '../Assets/Products/stone veneer/image (3).jpg';
import laminateSheet1 from '../Assets/Products/laminate/image (1).jpg';
import laminateSheet2 from '../Assets/Products/laminate/image (2).jpg';
import laminateSheet3 from '../Assets/Products/laminate/image (3).jpg';
import veneerSheet1 from '../Assets/Products/veneer/image (1).jpg';
import veneerSheet2 from '../Assets/Products/veneer/image (2).jpg';
import veneerSheet3 from '../Assets/Products/veneer/image (3).jpg';
import plywood1 from '../Assets/Products/plywood/image (1).jpg';
import plywood2 from '../Assets/Products/plywood/image (2).jpg';
import plywood3 from '../Assets/Products/plywood/image (3).jpg';
import pvcSheet1 from '../Assets/Products/pvc/image (1).jpg';
import pvcSheet2 from '../Assets/Products/pvc/image (2).jpg';
import pvcSheet3 from '../Assets/Products/pvc/image (3).jpg';
import flooringSheet1 from '../Assets/Products/flooring/image1.jpg';
import flooringSheet2 from '../Assets/Products/flooring/image2.jpg';
import flooringSheet3 from '../Assets/Products/flooring/image3.jpg';
import designerWallpaper1 from '../Assets/Products/wallpaper/image (1).jpg';
import designerWallpaper2 from '../Assets/Products/wallpaper/image (2).jpg';
import designerWallpaper3 from '../Assets/Products/wallpaper/image (3).jpg';
const products = [
  {
    title: 'Louvers Panel',
    images: [
      LouverPanel1,
      LouverPanel2,
      LouverPanel3
    ]
  },
  {
    title: 'Acrylic Sheet',
    images: [
      acrylicSheet1,
      acrylicSheet2,
      acrylicSheet3
    ]
  },
  {
    title: 'Stone Veneer',
    images: [
      stoneveneer1,
      stoneveneer2,
      stoneveneer3
    ]
  },
  {
    title: 'Laminate Sheet',
    images: [
      laminateSheet1,
      laminateSheet2,
      laminateSheet3
    ]
  },
  {
    title: 'Veneer Sheet',
    images: [
      veneerSheet1,
      veneerSheet2,
      veneerSheet3
    ]
  },
  {
    title: 'Plywood',
    images: [
      plywood1,
      plywood2,
      plywood3
    ]
  },
  {
    title: 'PVC Sheets',
    images: [
      pvcSheet1,
      pvcSheet2,
      pvcSheet3
    ]
  },
  {
    title: 'Flooring Sheet',
    images: [
      flooringSheet1,
      flooringSheet2,
      flooringSheet3
    ]
  },
  {
    title: 'Designer Wallpaper',
    images: [
    designerWallpaper1,
    designerWallpaper2,
    designerWallpaper3
    ]
  },

];

const ProductsPage = () => {
  const [showCarousel, setShowCarousel] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);

  const handleShowCarousel = (product) => {
    setCurrentProduct(product);
    setShowCarousel(true);
  };

  const handleCloseCarousel = () => {
    setShowCarousel(false);
    setCurrentProduct(null);
  };

  return (
    <Container className="mt-5">
      <Row>
        {products.map((product, index) => (
          <Col key={index} md={4} className="mb-4">
            <Card className="product-card">
              <Card.Img variant="top" src={product.images[0]} className="product-image" />
              <Card.Body className="text-center">
                <Card.Title>{product.title}</Card.Title>
                <Button variant="secondary" color="error" onClick={() => handleShowCarousel(product)}>See More</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {currentProduct && (
        <Modal show={showCarousel} onHide={handleCloseCarousel} size="md">
          <Modal.Header closeButton>
            <Modal.Title>{currentProduct.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Carousel>
              {currentProduct.images.map((image, idx) => (
                <Carousel.Item key={idx}>
                  <img
                    className="d-block w-100"
                    src={image}
                    alt={`Slide ${idx + 1}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
};

export default ProductsPage;
